.photo-section {
  display: flex;
  justify-content: center;
  background-image: url('../images/cueva_2.png');
  /* Ruta de la imagen de fondo */
  background-size: cover;
  /* Asegura que la imagen cubra todo el fondo */
  background-position: center;
  /* Centra la imagen de fondo */
  padding: 20px;
  /* Añade un poco de relleno alrededor del contenido */
}

.photo-section img {
  max-width: 100%;
  /* Ajusta el ancho máximo de la imagen */
  align-items: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  /* Organiza las imágenes en columna */
  align-items: center;
  /* Centra las imágenes horizontalmente */
}

.image-container img {
  max-width: 100%;
  /* Ajusta el ancho máximo de las imágenes */
  max-height: 100%;
  /* Ajusta la altura máxima de las imágenes */
  margin-bottom: 20px;
  /* Espacio entre las imágenes */
}

.inline-images {
  display: flex;
  justify-content: space-between;
  /* Distribuye las imágenes de manera uniforme */
  max-width: 100%;
  /* Ajusta el ancho máximo del contenedor */
  margin-bottom: 20px;
  /* Espacio entre las imágenes */
}

.inline-images img {
  max-width: calc(33% - 10px);
  /* Ajusta el ancho de cada imagen */
}

/* Agrega la animación de rotación a la imagen one */
.inline-images img:nth-child(1) {
  animation: rotate360 4s linear infinite;
  /* Rota la imagen sobre sí misma */
}

/* Agrega la animación de rotación a la imagen one */
.inline-images img:nth-child(3) {
  animation: rotate360 4s linear infinite; /* Rota la imagen sobre sí misma */
}

/* Animación de rotación */
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
    /* Inicia en 0 grados */
  }

  to {
    transform: rotate(-360deg);
    /* Termina en -360 grados para girar en sentido antihorario */
  }
}

/* Animación de movimiento vertical al segundo hijo (imagen del medio) */
.inline-images img:nth-child(2) {
  animation: moveUpDown 3s ease-in-out infinite; /* Movimiento vertical */
}

/* Animación de movimiento vertical */
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0); /* Posición inicial */
  }
  50% {
    transform: translateY(-50px); /* Mueve la imagen 10px hacia arriba */
  }
}

.photo-section img:hover {
  transform: translate(-2px,
      -2px);
  /* Mueve la imagen hacia arriba y hacia la izquierda */
  animation: shake 0.1s infinite alternate;
  /* Agrega una animación de temblor */
}

@keyframes shake {
  from {
    transform: translate(-2px,
        -2px);
    /* Mueve la imagen hacia arriba y hacia la izquierda */
  }

  to {
    transform: translate(2px,
        2px);
    /* Mueve la imagen hacia abajo y hacia la derecha */
  }
}