.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
    display: column;
}

.title {
  font-size: 36px; /* Tamaño del texto del título */
  text-align: center; /* Centrar horizontalmente */
  margin-top: 20px; /* Espacio superior */
  width: 300px;
}


.text-container {
  position: relative;
}

.text {
  position: absolute;
  display: none;
}

.text.show {
  display: block;
}

.top-left {
  top: 10px;
  left: 400px;
  width: 200px;
}

.top-right {
  top: 10px;
  right: 600px;
  width: 200px;
}

.bottom-center {
  bottom: 20px;
  left: 180%;
  transform: translateX(-50%);
  width: 200px;
}

.gif-image {
  width: 400px; /* Ajustar el tamaño según sea necesario */
  height: 400px;
  cursor: pointer;
  align-self: flex-end;
  transition: filter 0.3s ease-in-out; /* Agregar transición para suavizar el efecto */
}

.gif-image:hover {
  filter: brightness(200%); /* Ajustar el valor según se desee el brillo */
}

.footer {
  text-align: center;
  background-color: rgb(83, 15, 15);
  border-top: 5px solid;
}
