.barra {
  width: 100%;
  height: 50px; /* Ajusta la altura de la barra según sea necesario */
  background-color: rgb(83, 15, 15);
  overflow: hidden;
  color: red;
}

.texto-animado {
  display: flex;
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  align-items: center; /* Centra verticalmente el texto */
  font-size: 24px; /* Tamaño de fuente más grande */
  animation: moverTexto 22s linear infinite; /* Define la animación */
  margin-top: 12px;
  word-spacing: 18px;
}

@keyframes moverTexto {
  0% {
    transform: translateX(100%); /* Inicia fuera de la pantalla a la derecha */
  }
  100% {
    transform: translateX(
      -100%
    ); /* Termina fuera de la pantalla a la izquierda */
  }
}
